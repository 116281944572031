import { graphql } from 'gatsby';
import { block } from 'bem-cn';

import Layout from 'layouts/';
import Container from 'components/Container';
import MainBanner from 'components/MainBanner';
import Title from 'components/Title';
import FreeTrialForm from 'components/Forms/freeTrial';

import {
	faqItem,
	insideLink,
	faqBlock,
	outsideLink,
	renderAst,
} from 'components/markDownComponents';

const renderComToolkit = {
	faq: faqBlock,
	'outside-link': outsideLink,
	'faq-item': faqItem,
	'inside-link': insideLink,
};

const faqRenderCom = renderAst(renderComToolkit);

import './style.scss';

const cn = block('free-trial-page');

const FreeTrialPage = ({ data }) => {
	const { htmlAst } = data.markdownRemark;
	const { seo, title, subtitle } = data.markdownRemark.frontmatter;

	return (
		<Layout seo={seo}>
			<Container className={cn('wrap')}>
				<MainBanner
					title={title}
					image='gianalyzer-banner-start-free-trial.png'
					alt='Add GIAnalyzer add-in into your Excel and start 30-day free trial'
					imageClassName={cn('image')}
				>
					<Title level={6} color='secondary'>
						{subtitle}
					</Title>
					<FreeTrialForm className={cn('form')} />
				</MainBanner>
				<div className={cn('content')}>{faqRenderCom(htmlAst)}</div>
			</Container>
		</Layout>
	);
};

export default FreeTrialPage;

export const query = graphql`
	query getFreeTrialPageData {
		markdownRemark(frontmatter: { slug: { eq: "/free-trial" } }) {
			htmlAst
			frontmatter {
				title
				subtitle
				seo {
					description
					image
					link
					title
				}
			}
		}
	}
`;
