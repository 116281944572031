import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';

import { offlineOnlineVersionSource } from 'assets/constants';

import MiniForm from '../miniForm';
import Button from 'components/Button';
import Loader from 'components/Loader';

import './style.scss';

const cn = block('free-trial-buttons-block');

const FreeTrialButtonVersion = ({
	button,
	isLoader,
	isSend,
	isButtonClick,
	handler,
}) => {
	useEffect(() => {
		isSend && isButtonClick && document.getElementById(button.id).click();
	}, [isSend]);

	const onClickHandler = () => handler(button.id);

	return (
		<Button
			isRed={!!button.color}
			onClick={onClickHandler}
			className={cn('button')}
			size='large'
			disabled={isLoader}
		>
			{isLoader && isButtonClick ? (
				<Loader isDefault isWite={!!button.color} />
			) : (
				button.text
			)}
		</Button>
	);
};

const ButtonsGroup = ({ isLoader, isSend }) => {
	const [isButtonClick, setButtonClick] = useState(false);
	return (
		<div className={cn('wrap')}>
			{offlineOnlineVersionSource.map((button, index) => (
				<Fragment key={button.id}>
					<FreeTrialButtonVersion
						button={button}
						isLoader={isLoader}
						isSend={isSend}
						handler={(buttonId) => setButtonClick(buttonId)}
						isButtonClick={isButtonClick === button.id}
					/>
					<a id={button.id} href={button.link} download hidden />
				</Fragment>
			))}
		</div>
	);
};

const FreeTrialForm = ({ className }) => (
	<MiniForm
		className={className}
		buttonBlock={(isLoader, isSend) => (
			<ButtonsGroup isLoader={isLoader} isSend={isSend} />
		)}
		massage='freeTrial'
		requestUrl='licenses/trial'
		langDetect
	/>
);

FreeTrialForm.propTypes = {
	className: PropTypes.object,
};

export default FreeTrialForm;
