import { useContext } from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';

import { supportExcelText, windows710Text } from 'assets/constants';

import Heading from 'components/Heading';
import Image from 'components/Image';
import Title from 'components/Title';
import { DeviceContext } from 'layouts/context';

import './style.scss';

const cn = block('main-banner');

const ImageBanner = ({ isTablet, className, image, alt }) => (
	<div className={cn('image', { wrap: true }).mix(className)}>
		<Image src={image} alt={alt} />
	</div>
);

const MainBanner = ({
	children,
	title,
	image,
	alt,
	buttonsBlock,
	className,
	imageClassName,
}) => {
	const { isMobile, isTablet } = useContext(DeviceContext);

	return (
		<section
			className={cn('wrap').mix(className)}
			role='contentinfo'
			aria-label='Main banner'
			id='main-banner'
		>
			<div className={cn('content')}>
				<Heading
					color='general'
					weight='bold'
					className={cn('heading')}
					align={isTablet ? 'center' : 'left'}
				>
					{title}
				</Heading>
				{children}
				{isMobile && <ImageBanner isTablet={isTablet} image={image} />}
				{!!buttonsBlock && buttonsBlock}
				<Title
					level={9}
					isBlock
					className={cn('remark')}
					align={isTablet ? 'center' : 'left'}
				>
					{supportExcelText}
					{isTablet ? ' ' : <br />}
					{windows710Text}
				</Title>
			</div>
			{!isMobile && (
				<ImageBanner
					isTablet={isTablet}
					image={image}
					alt={alt}
					className={imageClassName}
				/>
			)}
		</section>
	);
};

MainBanner.defaultProps = {
	image: `main-banner.png`,
};

MainBanner.propTypes = {
	title: PropTypes.string.isRequired,
	className: PropTypes.object,
	children: PropTypes.node,
	buttonsBlock: PropTypes.node,
	image: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	imageClassName: PropTypes.object,
};

export default MainBanner;
